@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');



/* base styles */
* {
  margin: 0;
  /*  font-family: "Roboto","Helvetica","Arial",sans-serif;*/
  /* color: #333; */
}
.navbar {
  /* padding: 20px; */
  display: flex;
  align-items: center;
  max-width: 600px;
  margin: 0 auto;
  /* border-bottom: 1px solid #f2f2f2; */
  /* border-bottom:solid 1px var(--primary-color); */
  opacity:1.0;
  justify-content:space-between;
  overflow:hidden;
  padding:0.7rem 2rem;
  overflow:hidden;
  /* border-bottom:solid 1px var(--primary-color);opacity:0.9; */
  top:0;
  z-index:1;
  /* box-shadow:5px 0 5px -4px rgba(31,73,125,0.8), -5px 0 5px -4px rgba(31,73,125,0.8); */
  /* box-shadow:0 9px 0px 0px white, 0 -9px 0 0px white, 5px 0 5px -4px rgba(31,73,125,0.8),-5px 0 5px -4px rgba(31,73,125,0.8) ; */
  position:fixed;
  left:0;
  right:0;
}

.navbar h1 {
  font-size: 30px;
  color: #ee2424;
}
.navbar a {
  max-width: 600px;
  width:100%;
  text-decoration: none;
  padding: 6px;
  float: center;

}
.navbar a:hover {
  color: #f1356d;
}
.content {
  /* max-width: 600px; */
  margin: 80px auto;
  padding: 20px;
}
/*Participant List Style*/
.plist {
  max-width: 600px;
  margin: 40px auto;
  padding: 20px;
}
.ParticipantList {
  padding: 10px 16px;
/*display: flex;
  align-items: center;
  max-width: 600px;*/
  margin: 20px 0;
  border-bottom: 1px solid #fafafa;
}

.part-preview {
  padding: 10px 16px;
/*display: flex;
  align-items: center;
  max-width: 600px;*/
  margin: 20px 0;
  border-bottom: 1px solid #fafafa;
}
.part-preview:hover {
box-shadow: 1px 3px 5px rgba(0,0,0,0.1);
}
.part-preview h2 {
font-size: 12px;
color: #f1356d;
margin-bottom: 8px;
}

.create{
  max-width: 600px;
  margin: 10px auto;
  padding: 10px;
  text-align: center;
  display:block;
}
.create input, .create textarea, .create select {
  width: 100%;
  /* padding: 5px 5px; */
  margin: 1px 1px;
  border: 1px solid #ddd;
  box-sizing: border-box;
  text-align: left;
}
.reqfields TextField {
  width: 50%;
  padding: 5px 10px;
  margin: 1px 0px;
  border: 1px solid #ddd;
  box-sizing: border-box;
  text-align: left;

}
.notreqfields TextField {
  width: 50%;
  padding: 5px 10px;
  margin: 1px 0px;
  border: 1px solid #ddd;
  box-sizing: border-box;
  text-align: left;

}

.create label {
text-align: left;
display:block;
}

.create h1 {
  /*
  color: #FF0000;
  margin-bottom: 30px;
  animation: blinkingText 1s infinite; */
  margin: 18px 0;
  display: block;
 padding-left: 0px;
 text-indent: 0px;
 color:#0D1229;
 font-size: 25px;
 font-family:'Roboto','Helvetica','Arial',sans-serif;
}

.create h2 {
  /*
  color: #FF0000;
  margin-bottom: 30px;
  animation: blinkingText 1s infinite; */
  margin: 30px 0;
  display: block;
 padding-left: 15px;
 text-indent: -0px;
 color:#FF0000;
 font-size: 18px;
}
/* <span class='a-xxx'>a</span>  */
.reqfields label:after{
  content:' *';
  color:#e32;
  font-size: 22px;
}
.reqfiles {
  content:' *';
  color:#e32;
  font-size: 22px;
}

@keyframes blinkingText {
  0%{color:red;}
  /* 40%{color:orange;} */
  /* 60%{color:pink;} */
  /* 90%{color:transparent;} */
  /* 100%{color:green;} */
}

/*Remove arrow from select Textfield
.MuiFormControl-root .MuiSvgIcon-root.MuiSelect-icon {
 display: none;
}*/

.create button {
  background: #e20e12;
  color: #fff;
  border: 0;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
}
.code TextField{
  padding: 0px 5px;
  margin: 5px 0px;
  border: 1px solid #ddd;
  box-sizing: border-box;
  /* display:block; */
  align:left;
  /* vertical-align: center; */
  /* vertical-align: center; */
  padding: 2px;
  margin: 5px 0px;
}
.code label{
  vertical-align: center;
  padding: 2px;
  margin: 5px 0px;
  text-align: right;

}
.code Stack{
  display: flex;
 flexDirection: column;
 justifyContent: center;
}
.code button {
  justify-content: center;
  background: #e20e06;
  color: #fff;
  margin: 5px 0px;
  border: 0;
  padding: 2px;
  border-radius: 1px;
  cursor: pointer;
}

.code .MuiButton-containedPrimary {
 background-color: #e20e06 !important; /* Replace with your desired color */
}

.code .MuiButton-containedPrimary:hover {
 background-color: #0D1229 !important; /* Replace with your desired hover color */
}

.create .MuiButton-contained {
 background-color: #e20e06 !important; /* Replace with your desired color */
}

.create .MuiButton-contained:hover {
 background-color: #0D1229 !important; /* Replace with your desired hover color */
}
.create p {
  margin: 5px 0;
  display: block;
 padding-left: 15px;
 text-indent: -0px;
font-weight: 900;

}

/* upload form styles */
.upload form{
  margin: 10px auto 10px;
  text-align: center;
}
.upload label input{
  height: 0;
  width: 0;
  opacity: 0;
}
.upload label{
  display: block;
  width: 30px;
  height: 30px;
  border: 1px solid var(--primary);
  border-radius: 50%;
  margin: 10px auto;
  line-height: 30px;
  color: var(--primary);
  font-weight: bold;
  font-size: 24px;
}
.upload label:hover{
  background: var(--primary);
  color: white;
}
.output{
  height: 60px;
  font-size: 0.8rem;
}
.error{
  color: red;
}

.berror .MuiInputBase-root {
   border-color:  red;
}


.photouploadpreview {
  height: 100px;
  width: 100px;
  margin: 10px 0;
}
.uploadphotos {
  margin: 0px 0px;
  vertical-align:top;
  text-align: left;
  padding: 1px;
}

.uploadphotoscheck {
  margin: 0px 20px;
  vertical-align:top;
  text-align: left;
  padding: 5px;
}

.inputfile{
  margin: 5px 5px;
  width:5px;
	height: 30px;
	z-index: -1;
  display: inline-block;
  cursor: pointer;

}
.App{
margin: 0 auto;
font-family: "Quicksand";
}

.unordered-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.list-item {
  margin: 0 auto;
  padding: 0;
  list-style-type: none;
}
.App  ul{
  display: block;
  list-style-type: disc;
  margin-block-start: 2px;
  margin-block-end: 2px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 5px;
  font-family: "Quicksand";

}

.left-section {
  flex-direction: column;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.right-section {
  flex-direction: column;
  /* margin: 5px 5px;
  padding: 5px; */
  list-style-type: none;
}

/* .right-section inputfile {
  flex-direction: column;
  margin: 5px 5px;
  padding: 5px;
  height: 5px;
} */

.table{
  text-align: top;

}

.checked-item,
.not-checked-item {
}
.checked-item {
  text-decoration: line-through;
}
.agree label:after{
  content:' *';
  /* color:#e32; */
  color:#e20e12;
  font-size: 22px;
}

.agree label{
  display: block;
       /* padding-left: 0px;
       text-indent: 0px; */
       color:#FF0000;
       /* margin: 5px; */
}

.diplomaphoto label{
  display: block;
       /* padding-left: 0px;
       text-indent: 0px; */
      /* color:#FF0000;*/
       /* margin: 5px; */
}

.agree a{
        /* padding: 0; */
        /* margin: 0; */
        vertical-align: bottom;
        position: relative;
        top: 5px;
        margin: 5px 10px;
        vertical-align:top;
        text-align: left;
        padding: 1px;
}
.agree Stack{
  display: block;
       padding-left: 5px;
       text-indent: -5px;
       /* color:#FF0000; */
       margin: 0px;
}



.paylater Stack{
  display: block;
       padding-left: 5px;
       text-indent: -5px;
       margin: 0px;
       align-items: right;
       vertical-align: center;
       /* position: center; */
}
.paylater span {
    /* float: right; */

        padding: 0px 0px;
        padding-left: 5px;
        margin: 0px 0px;
        align-items: left;
        vertical-align: center;
        position: left;
        top: 0px;
        margin-left:20px;
        /* text-indent: -5px; */
         /* max-width: 100px; */
        flex-direction: row;
}
.paylater TextField{
    align-items: right;
    vertical-align: center;
margin: 10px 0;
}

.description {
  padding: 10px;
  display: flex;
  align: center;
  /* max-width: 600px; */
  margin: 10px 0;
  font-size: 18px;
  border-bottom: 1px solid #f2f2f2;
}
.errors {
  padding: 10px;
  text-align: left;
}

.errors p {
  color: red;
  margin: 10px 0;
  display: block;
 /* padding-left: 15px; */
 text-indent: -0px;
 font-size: 16px;
font-weight: 900;

}

.proceed {
  padding: 10px;
}
.proceed p {
  color: green;
  margin: 10px 10;
  display: block;
 /* padding-left: 15px; */
 text-indent: -0px;
 font-size: 16px;
font-weight: 900;

}

.SuccessPage {
  max-width: 600px;
  margin: 40px auto;
  padding: 20px;
  text-align: center;
  display: block;
 font-size: 15px;
 color:#FF0000;
}

.SuccessPage p{
  /*
  color: #FF0000;
  margin-bottom: 30px;
  animation: blinkingText 1s infinite; */
  margin: auto;
  display: block;
 padding-left: 0px;
 text-indent: 0px;
 font-size: 16px;
}
.orangespan{
  color:#fe5a1d;
  font-weight:bold;
}

.registrations {
  max-width: 100%;
  margin: 20px 20px;
  padding: 20px;
}

.pendingpayments { /*SEt margin for logo distance here*/
  max-width: 100%;
  margin: 10px 10px;
  padding: 20px;
}

.login {
  height: 40px; /* image's height */
display: table-cell;
vertical-align: middle;
}
.circular{
font-weight: normal;
font-size: 18px;
}

.table-cell-trucate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.MuiCheckbox-colorPrimary {
  background: #fbb !important;
}
.MuiCheckbox-colorSecondary{
  background: #FF3592 !important;
  color:#3f3849 !important;
}

.MuiButton-containedSecondary {
 background-color: #e20e06 !important; /* Replace with your desired color */
}

.MuiButton-containedSecondary:hover {
 background-color: #0D1229 !important; /* Replace with your desired hover color */
}


.modal-dialog  {
  flex: 1;
  height: 100%;

  z-index: 5;
  align-items: center;
  justify-content: center;

}
.modal-header {
  z-index: 5;
  flex: 1;
  position: fixed;
  justify-content: center;
  flex-direction: row;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  background-color: rgba(0,0,0,0.6);
  align-items: center;
  justify-content: center;

}


.modal-body {
  width: calc(100% - 50px);
  height: 100%;
  align-items: center;
  flex: 1;
  object-fit: cover;
  justify-content: center;
  background: "#FFFFFF";
  overflow: hidden;
  flex-direction: row;
  position: fixed;
top: 50px;
left: 0px;
bottom: 0px;
right: 0px;
z-index: 5;
background-color: rgba(0,0,0,0.3);
}

.modal-dialog img {
  flex: 1;
  height: 90%;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;

}
.modal-dialog header {
  position:relative;
  text-align: center;
  padding-top: 25px;
}

.compareimages {
  float: left;
  width: 30%;

  flex: 1;
  height: 90%;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
}

.combination{
  border-style: solid;
border-width: thin;
border-color: #747484;

}
